@import url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Cairo:wght@400;700&display=swap');

h1, h2, h3, h4, h5, h6, p, li, a, div, button {
  font-family: 'Amiri', serif;
}
.my-font {
  font-family: 'Amiri', sans-serif;
}
.ant-form-item-label  {
    display: flex !important;
}