@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&family=Cairo:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Cairo', sans-serif;
  background-color: #fafafa;
  min-height: 100vh;
}


.my-font{
  font-family: 'Cairo', sans-serif;
}

h1, h2, h3, h4, h5, h6, p, li, a, div, button {
  font-family: 'Amiri', serif;
}
.my-font {
  font-family: 'Amiri', sans-serif;
}
.ant-form-item-label  {
    display: flex !important;
}
